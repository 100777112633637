<template>


  <div>
    <div style="font-size: 25px;text-align: center">文章列表</div>
    <div>
      <a-table
          ref="table"
          :columns="columns"
          :data-source="data"
          :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <!--        eslint-disable-next-line vue/no-unused-vars -->
        <template slot="operation" slot-scope="text, record">

          <router-link :to="'/BackEnd/article/ArticleList/EditArticle/'+record.id">编辑 / 删除</router-link>

        </template>

      </a-table>
    </div>


  </div>

</template>


<script>
export default {
  name: "ArticleList",
  data() {
    return {

      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',

        },
        {
          title: '标题',
          dataIndex: 'posts_name',
          key: 'posts_name',
        },

        {
          title: '文章权重',
          dataIndex: 'index',
          key: 'index',
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',

        },
        {
          title: '最后更新时间',
          dataIndex: 'update_time',
          key: 'update_time',

        },
        {
          title: 'operation',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
        },
      ],
      data: [],


    }
  },
  methods: {},
  created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.38'
    this.$axios.post('/Admin/Api/Posts/GetPostsList', {}, {
      headers: {
        "Access-Token": this.$store.getters.AcToken
      }
    }).then(value => {
      console.log(value.data)
      this.data = JSON.parse(value.data.data).pageData
      // this.pagination.total = parseInt(JSON.parse(value.data.data).count)
      // eslint-disable-next-line no-unused-vars

    })
  }
}

</script>

<style scoped>

</style>
